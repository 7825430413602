html,
body {
  width: 100%;
  line-height: 1;
}

body,
ul,
ol,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
form,
fieldset,
legend,
input,
textarea,
button,
p,
blockquote,
th,
td,
pre,
xmp {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

caption,
th {
  text-align: inherit;
}

ul,
ol,
menu {
  list-style: none;
}

fieldset,
img {
  border: 0;
}

img,
object,
input,
textarea,
button,
select {
  vertical-align: middle;
}

article,
aside,
footer,
header,
section,
nav,
figure,
figcaption,
hgroup,
details,
menu {
  display: block;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "\0020";
}

textarea {
  overflow: auto;
  resize: vertical;
}

input,
textarea,
button,
select,
a {
  outline: 0 none;
  border: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
ins,
s,
u,
del {
  text-decoration: none;
}

sup,
sub {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
}

a {
  text-decoration: none;
}

input::-ms-clear,
input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}